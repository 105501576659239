<template>
  <div class="cookies content">
    <section id="page-title" class="page-title-mini">
			<div class="container clearfix">
				<h1>{{ $t('legal.cookies.title') }}</h1>
			</div>
		</section>
    <div class="content-wrap">
      <div class="container clearfix">
        <Fragment path="cookies"></Fragment>
      </div>
    </div>
  </div>
</template>

<style>

</style>

<script lang="ts">
import Vue from '@fwk-node-modules/vue';
import { Component } from '@fwk-node-modules/vue-property-decorator';
import { mixins } from '@fwk-node-modules/vue-class-component';
import { mapState, mapGetters } from '@fwk-node-modules/vuex';
import GenericPage from '@fwk-client/components/mixins/GenericPage.vue';
import { routerTypes, languagesTypes } from '@fwk-client/store/types';
import Fragment from '@fwk-client/components/panels/Fragment.vue';

@Component({
  components: {
	  Fragment
  },
  computed: {
	  ...mapGetters({
          currentLanguageCode : 'languages/' + languagesTypes.getters.GET_CURRENT_LANGUAGE
        }),
  }
})
export default class Cookies extends mixins(GenericPage) {

	

}
</script>